<template>
  <VarianceMenu></VarianceMenu>


  <div v-for="(post, index) in generatedContentStore.posts" :key="index">
<!--    <textarea rows="6" cols="50"  :value="post.content" @input="value = $event.target.value;generatedContentStore.editPost(index, $event.target.value)" />-->
    <p :style="{'text-align': post.content.match(/[\u0600-\u06FF]/) ? 'right' : 'left' }" :dir="post.content.match(/[\u0600-\u06FF]/) ? 'rtl' : 'ltr'" style="width: 80%; font-size: 1.4rem;margin: auto;background-color: #2b2f3b;
    padding: 16px;
    border-radius: 16px;
    margin-top: 16px;" v-html="formatParagraph(post.content)"></p>

<!--  <button id="copy" @click="copy(post.content)">-->
<!--    copy-->
<!--  </button>-->
    <button class="ui icon button copyBtn" id="copy" @click="copy(post.content)">
      <i class="cloud icon" style="margin: 0px 4px!important;"></i> copy
    </button>
  </div>
</template>

<script setup>
import VarianceMenu from "@/components/VarianceComponents/VarianceMenu.vue";
import {useGeneratedContentStore} from "@/stores/generatedContentStore";
const generatedContentStore = useGeneratedContentStore();

// import {onMounted } from "vue";
// import {useGeneratedContentStore} from "@/stores/generatedContentStore";
// import {useRoute, onBeforeRouteUpdate} from "vue-router";
// const route = useRoute();
//
// // const generated_content_id = route.params.generated_content_id
//
// const generatedContentStore = useGeneratedContentStore();
// generatedContentStore.generated_content_id =  route.params.generated_content_id;
//
//
// onBeforeRouteUpdate(async  (to, from, next) => {
//   // const nextRouteQuery = resolved.query; // Next route's query parameters
//   // Access other properties of the next route as needed
//   generatedContentStore.generated_content_id =  to.params.generated_content_id;
//   console.log(generatedContentStore.generated_content_id)
//
//   await generatedContentStore.init();
//   next()
//
// });

function formatParagraph(paragraph) {
  // Apply any formatting logic here
  // For example, you can replace certain patterns with HTML tags
  // Here's a basic example:
  paragraph = paragraph.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'); // Bold text
  paragraph = paragraph.replace(/__(.*?)__/g, '<em>$1</em>'); // Italic text
  paragraph = paragraph.replace(/\n\n/g, '<br><br>');

  // Add more formatting rules as needed
  return paragraph;
}

function copy(post){
  let originalContent = '';

  // Store the original content of the button
  originalContent = document.getElementById("copy").innerHTML;
  const tempTextarea = document.createElement('textarea');

  tempTextarea.value = post; // Set its value to the inner text of the <p> tag
  document.body.appendChild(tempTextarea); // Append it to the document

  // Select the text inside the textarea
  tempTextarea.select();
  tempTextarea.setSelectionRange(0, 99999); // For mobile devices

  // Copy the selected text
  document.execCommand('copy');

  // Remove the temporary textarea from the document
  document.body.removeChild(tempTextarea);
  document.getElementById("copy").innerText = 'Copied!';
  document.getElementById("copy").classList.add('green');

  setTimeout(() => {
    document.getElementById("copy").innerHTML = originalContent;
    document.getElementById("copy").classList.remove('green');

  }, 1500);

}
//
// function getRouteLink(channel) {
//   // Check the type of the current list item and return the appropriate router link
//   if(channel == "video"){
//     return '/ShowRepurposedVideo/'+ generatedContentStore.content_source_id +'/' + generatedContentStore.generated_content_id ;
//
//   }  else if(channel == "twitter"){
//     return '/ShowRepurposedArticle/'+ generatedContentStore.content_source_id +'/' + generatedContentStore.generated_content_id ;
//
//   }else {
//     return '/ShowRepurposedLinkedin/'+ generatedContentStore.content_source_id +'/' + generatedContentStore.generated_content_id ;
//
//   }
//
// }
//
// function hasTwitter(){
//   return (generatedContentStore.outputChannels.includes("twitter")) ? true : false;
// }
// function hasVideo(){
//   return (generatedContentStore.outputChannels.includes("videos")) ? true : false;
// }
// function hasLinkedin(){
//   return (generatedContentStore.outputChannels.includes("linkedin")) ? true : false;
// }
//
//
// onMounted(() => {
//   //TODO: save the response to the store, then redirect to to correct url
//   generatedContentStore.init();
// })

</script>

<style scoped>
.LinkedinActive{
  background-color: rgb(17 65 193)!important;
  color: white!important;
  font-weight: 400!important;
}
/*.item{*/
/*  font-size: 1.3rem !important;*/
/*}*/
.icon{
  margin: 0px 9px !important;
  font-size: 21px;
}
.copyBtn{
  margin: 16px!important;
  background-color: #6723ff!important;
  color: white!important;
}
.green{
  background-color: #aceeac;
}
</style>
