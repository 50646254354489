<template >
  <!-- TODO: design tweet result -->
  <div class="tweet" v-if="!tweet.is_deleted">
    <div id="reorder" data-tooltip="Hold & Drag to re-order Tweets" class="  popup top left transition">
      <i class="bi bi-grip-vertical handle" ></i>

    </div>
<!--        <textarea rows="6" cols="50"  :value="tweet.tweet" @input="value = $event.target.value;TweetThreadStore.editTweetById(tweet.tweet_id, $event.target.value)" />-->
        <textarea class="tweetText" rows="6" cols="50"  :value="tweet.tweet" @input="value = $event.target.value;TweetThreadStore.editTweetById(index, $event.target.value)" />

    <div @click="TweetThreadStore.removeAt(tweet.tweet_id)" class="ui vertical animated button delete_tweet_btn" tabindex="0">
      <div class="hidden content">Delete</div>
      <div class="visible content">
        <i class="trash icon"></i>
      </div>
    </div>

  </div>

  <div v-if="!tweet.is_deleted" @click="TweetThreadStore.addAt(index+1)" class="ui vertical animated button addTweetButton" tabindex="0">
    <div class="hidden content">Add Tweet</div>
    <div class="visible content">
      <i class="plus icon"></i>
    </div>
  </div>

</template>
<script setup>
// import { useTweetThreadStore } from "@/stores/tweetThreadStore";
import {useGeneratedContentStore} from "@/stores/generatedContentStore";
const TweetThreadStore =  useGeneratedContentStore();

defineProps({
  tweet: Object,
  index: Number
})

</script>

<style scoped>

#reorder{
position: absolute;
  top: 0;
  left: -16px;

}
#reorder:hover{
  cursor: pointer;
}


.delete_tweet_btn{
  position: absolute!important;
  bottom: 11px!important;
  right: 20px!important;
  background: #cc0808!important;
  color: white!important;
}

.addTweetButton{
  margin: 10px 0px!important;
  background: white!important;
  border: 1px solid black!important;
  color: #2c2c2c!important;
  min-width: 93px;
}

.addTweetButton:hover{
  background: #2c2c2c!important;;
  border: 1px solid black!important;;
  color: white!important;;
}

.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
.tweet{
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; 
 width: 100%;
  position: relative;
}

textarea{
/*  background-color: white;*/
/*  border:none;*/
/*  resize: none;*/
/*  outline: none;*/
/*  font-size: 25px;*/
/*  font-family: 'Monaco', courier, monospace;*/
  display: block;
  width: 90%;
/*  margin: 10px auto;*/
}

textarea:focus{
  background-color: rgb(43, 47, 59)!important;
  border:none;
/*  border:none;*/
/*  resize: none;*/
/*  outline: none;*/
/*  font-size: 25px;*/
/*  font-family: 'Monaco', courier, monospace;*/
  display: block;
  width: 90%;
/*  margin: 10px auto;*/
}

.tweetText{
  font-family: Poppins, Avenir, Roboto, 'PingFang SC', Helvetica, Arial, 'Microsoft YaHei', 'WenQuanYi Zen Hei', 'WenQuanYi Micro Hei', sans-serif;
  font-size: 1.2rem;
  background-color: rgb(20, 23, 31);
  color: white;
  padding: 16px;
  margin: 0px;
  border-radius: 10px;
  border:none;
  resize: none;
}

</style>