<template>
  <h1 class="margin-top-30" style="margin-bottom: 16px"> Details of Output Videos</h1>
  <ul>
    <li>
      <h3>How many videos do you want?</h3>
      <input style="background-color: #15171f; color: rgb(226, 227, 231);" type="number" placeholder="max 5"  id="numberOfVideos" name="numberOfVideos" max="5" min="1" v-model=addVideoVarStore.video.number_of_clips />
    </li>
    <li>
      <h3>
        Average video duration in seconds
      </h3>

      <input style="background-color: #15171f; color: rgb(226, 227, 231);" type="number" placeholder="max 300"  id="durationPerVideo" name="durationPerVideo" max="300" min="60" v-model=addVideoVarStore.video.duration_of_clip />

    </li>
  </ul>
</template>

<script setup>
import {useAddVideoVarStore} from "@/stores/addVideoVarStore";

const addVideoVarStore = useAddVideoVarStore();

</script>

<style scoped>

</style>