<template>
  <ul class="video-list">
    <li class="video-element" v-for="repurposed_video in generatedContentStore.videos" :key="repurposed_video.id">

      <repurposed-video :video="repurposed_video"></repurposed-video>

    </li>
  </ul>
</template>

<script setup>

import RepurposedVideo from "@/components/VarianceComponents/videoComponents/repurposedVideo.vue";
import {useGeneratedContentStore} from "@/stores/generatedContentStore";

const generatedContentStore = useGeneratedContentStore();

</script>

<style scoped>
.video-element{
  display: block;
  background-color: rgb(20, 23, 31);
  margin-bottom: 24px;
  padding: 24px!important;
  border-radius: 16px;
}
.video-element:hover{
  background-color: rgb(43, 47, 59);

}
.video-list{
  flex-direction: column;
}
</style>

