<template>

  <div class="ui one column">
    <div class="addNewArticleDiv margin-top-30" >
      <h1 class="">Enter the Article URL</h1>
      <div class="input-with-button">
        <input style="background-color: #15171f; color: rgb(226, 227, 231);" type="text" placeholder="article title"  v-model="addArticleVarStore.articleURL">
        <button class="cs-light-btn"  @click="addArticleVarStore.fetchArticleFromURL(addArticleVarStore.articleURL,'substack')">Fetch article</button>
      </div>
    </div>


    <manual-entry v-if="addArticleVarStore.isArticleContentFetched"></manual-entry>
  </div>
</template>

<script setup>
import {useAddArticleVarStore} from "@/stores/addArticleVarStore";
import ManualEntry from "@/components/AddNewArticleComponents/ManualEntry.vue";

const addArticleVarStore = useAddArticleVarStore();

</script>

<style scoped>
.input-with-button{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}


input[type="text"]{

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border: 1px solid #999999;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 16px 20px;
  max-width: 50%;
}
input[type="text"]:focus-visible,
input[type="text"]:focus,
input[type="text"]:active{
  border: 1px solid #999999;
  border-right: none;
}

.input-with-button input{
  flex:1;
  padding: 16px 20px;
}
.input-with-button button{
   color: #121212;
   background-color: rgb(226, 227, 231);
   min-width: 100px;
   min-height: 42px;
   border-radius: 0px 5px 5px 0px;
   cursor: pointer;
  padding:15px 15px;
  border: #999999;
}
.input-with-button button:hover{
   color: #ffffff;
  background-color: rgb(8 137 13);

}
</style>