import {defineStore} from "pinia";
import {fetchAllContentForUser, getUserConnectedAccounts} from "@/utilities/api";
import router from "@/router";


export const UserHomeStore = defineStore({
    id:'userHome',
    state: () => ({
        userGeneratedContents: [],
        userConnectedAccounts:[]

    }),
    getters: {
    },
    actions: {

        async getUserContent(){
            try {
                const response = await fetchAllContentForUser()
                if (response.data.status == -1 )   window.location.href = '/login';
                this.userGeneratedContents = response.data.filter(obj => obj.generated_content.length > 0);
                // console.log(response.data.filter(obj => obj.generated_content.length > 0))
                // console.log(this.userGeneratedContents[0].type)
                if(this.userGeneratedContents.length>0){
                    if (this.userGeneratedContents[0].generated_content[0].type === 'video') {
                        router.push('/ShowRepurposedVideo/'+ this.userGeneratedContents[0].id +'/' + this.userGeneratedContents[0].generated_content[0].gc_id);
                    } else if (this.userGeneratedContents[0].generated_content[0].type === 'twitter') {
                        router.push('/ShowRepurposedArticle/'+ this.userGeneratedContents[0].id +'/' + this.userGeneratedContents[0].generated_content[0].gc_id);
                    }else{
                        router.push('/ShowRepurposedPost/'+ this.userGeneratedContents[0].id +'/' + this.userGeneratedContents[0].generated_content[0].gc_id);
                    }

                }

            }catch (e) {
                console.log(e);
            }
        },

        async getUserConnectedAccounts(){
            try {
                const response = await getUserConnectedAccounts()
                if (response.data.status == -1 )   window.location.href = '/login';
                this.userConnectedAccounts = response.data.accounts;
                console.log(this.userConnectedAccounts)
                // console.log(response.data.filter(obj => obj.generated_content.length > 0))
                // console.log(this.userGeneratedContents[0].type)


            }catch (e) {
                console.log(e);
            }
        },
    }
});