<template>
  <div class="ui container" style="background-color: transparent;margin-top: 60px">
    <h1 class="ui header" style=" color: rgb(226, 227, 231)!important;">Account Settings</h1>

   <div>

       <div v-for="account in userHomeStore.userConnectedAccounts.youtube" v-bind:key="account.id"
           class="ui segment" style="background-color: transparent">

         <div class="channel-section">
           <h3>{{account.title}}</h3>
         </div>

       </div>

     <button class="ui youtube button" @click="connectToYouTube">
       <i class="youtube icon"></i>
       Connect To New YouTube Account
     </button>
   </div>


  </div>
</template>

<script setup>
 import axios from "axios";
 import {onMounted} from "vue";

 import {UserHomeStore} from "@/stores/userHomeStore";

 axios.defaults.withCredentials = true;
 axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
 axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_URL;
 axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';


 const userHomeStore = UserHomeStore();




 const connectToYouTube = async () => {
   try {
     const response = await axios.get(process.env.VUE_APP_BE+'/api/auth/youtube/redirect');
     window.location.href = response.data.url;

   } catch (error) {
     console.error(error);
   }
 };


 onMounted(() => {
       userHomeStore.getUserConnectedAccounts();
 })
</script>

<style scoped>
/* Add your component-specific styles here */


.channel-section{
  width: 60%;
  font-size: 1.4rem;
  margin: auto;
  background-color: #2b2f3b;
  padding: 16px;
  border-radius: 16px;
  margin-top: 16px;
}
</style>
