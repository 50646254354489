<template>
  <div class="ui one column">
    <div class="addNewArticleDiv margin-top-30">
      <h1 class="">{{ (addArticleVarStore.showManualView) ? 'Copy & Paste your article below' : 'Check Your Article Details' }}</h1>

      <input style="background-color: #15171f; color: rgb(226, 227, 231);" type="text" placeholder="article title" v-model="addArticleVarStore.article.title">
      <textarea style="background-color: #15171f; color: rgb(226, 227, 231);" rows="30" cols="90" v-model="addArticleVarStore.article.body"></textarea>
    </div>
  </div>
</template>

<script setup>
import {useAddArticleVarStore} from "@/stores/addArticleVarStore";
const addArticleVarStore = useAddArticleVarStore();

</script>

<style scoped>

input[type="text"]{

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border: 1px solid #999999;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 16px 20px;
  display: block;
  margin:auto;
  min-width: 70%;
  margin-top: 30px;
}
textarea{
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border: 1px solid #999999;
  border-radius: 5px;
  margin-top: 20px;
  resize: none;
  outline: none;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 20px;
}
</style>