<template>
  <h1 class="margin-top-30" style="margin-bottom: 16px"> Which Channel you need for Text output</h1>
  <ul>
    <li v-for="(channel,index) in addVideoVarStore.text_output_distribution_channels" :key="index">
      <input type="checkbox"
             :id="channel.name"
             :name="channel.name"  :value="channel.name"
             v-model="addVideoVarStore.selectedTextDistChannels" />

      <label :for="channel.name"  class="cs-cb">
        <i class="bi bi-text-paragraph radioIcon"></i>
        <span>{{channel.name}}</span>

      </label>
    </li>
  </ul>
</template>

<script setup>
import {useAddVideoVarStore} from "@/stores/addVideoVarStore";

const addVideoVarStore = useAddVideoVarStore();

</script>

<style scoped>
ul{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-content: center;
  margin-top: 30px;
}
ul li {
  list-style: none; display: inline-block;

}

/* Style the label to look like a button */
input[type="checkbox"]{
  /*display: none!important;*/
}

/* When the checkbox is checked, change the button's appearance */
/*input:checked ~ .cs-btn {*/
input[type="checkbox"]:checked + .cs-cb {
  background-color: rgb(103, 35, 255)!important;
  color: #fff;
  border-color: #121212;
}

.cs-cb {
  display: inline-block;
  padding: 6px 20px 16px 20px;
  border: 1px solid #121212;
  color: #121212;
  border-radius: 5px;
  background-color: rgb(251, 251, 251);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px ;
  cursor: pointer;
  font-size: 1.3rem;
  min-width: 160px;
}

.radioIcon{
  font-size: 3rem;
  display: block;
  margin: auto;
  margin: 16px auto;

}

.radioIcon:hover{
  color: white;
}
.cs-cb:hover{

  border: 1px solid #121212;
  color: white;
  border-radius: 5px;
  background-color: rgb(103, 35, 255)!important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px ;
  cursor: pointer;
}
.articleSourceTip{
  display: block;
  margin-top:8px;
  color: #616161;
}

</style>