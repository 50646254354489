import {defineStore} from "pinia";
import router from "@/router";
import {repurpose_video} from "@/utilities/api";

export const useAddVideoVarStore = defineStore({
    id:'addVideoVariables',
    state: () => ({
        currentStep:1,
        loader_text: "Repurposing in Progress",
        videoSource:0,
        video:{
            url:'',
            number_of_clips: 2,
            duration_of_clip: 60,
        },

        output_formats: [
            {
                id:1,
                name: "Video"
            },
            {
                id:2,
                name: "Text"
            }
        ],
        text_output_distribution_channels: [
            {
                id:1,
                name: "twitter"
            },
            {
                id:2,
                name: "linkedin"
            }
        ],
        selectedVideoOutputFormat: [],
        selectedTextDistChannels: [],
        isLoadingContent:false


    }),
    getters: {
        isFirstStep: (state) => {
            return (state.currentStep == 1) ? true : false;
        },
        isLastStep: (state) => {
            return (state.currentStep>=3) ? true:  false;
        },
        isURLEntered: (state) =>{
            return (state.video.url)
        },
        getNextButtonText(state){
            if(state.currentStep == 1){
                return "Select Output Format"
            }else if(state.currentStep == 2){
                return "Select Output Details"
            }else if(state.currentStep == 3){
                return "Repurpose Video"
            }
        },
        getPrevButtonText(state){
            if(state.currentStep == 1){
                return ""
            }else if(state.currentStep == 2){
                return "Re-Select Output Format"

            }else if(state.currentStep == 3){
                return "Select Output Details"
            }
        },
        hasVideoOutput(state){
            const  selectedVideoOutputArray= Object.values(state.selectedVideoOutputFormat)
            return selectedVideoOutputArray.includes("Video")
        },
        hasTextOutput(state){
            const  selectedVideoOutputArray= Object.values(state.selectedVideoOutputFormat)
            return selectedVideoOutputArray.includes("Text")
        }
    },
    actions: {
        goToNextStep () {
            if(!this.isLastStep) this.currentStep++;
        },
        gotPreviousStep () {
            if(!this.isFirstStep) this.currentStep--;
        },
        redirectToGeneratedContent(video_id, generated_content_id){
            router.push('/ShowRepurposedVideo/' +video_id + '/' +generated_content_id)
        },
        async  repurposeVideo(){

            try {
                this.isLoadingContent = true;

                const response = await repurpose_video({
                    video : this.video,
                    selectedVideoOutputFormat: this.selectedVideoOutputFormat,
                    selectedTextDistChannels: this.selectedTextDistChannels
                });
                if (response.data.status == -1 )   window.location.href = '/login';
                this.isLoadingContent = false;

                return this.redirectToGeneratedContent(response.data.video_id, response.data.generated_content_id)
            } catch (error) {
                console.error(error);

                if(error.message == "Network Error")
                {
                    this.loader_text = "It is taking longer than expected, results will show up soon on your home page..."

                    setTimeout(function() {
                        this.loader_text = "Repurposing in progress.."

                        window.location.href = '/';
                    }, 2000);
                }

            }

        }
    }
});
