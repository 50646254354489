import {defineStore} from "pinia";
import {isSessionExpired} from "@/utilities/api";
// import router from "@/router";
// import  axios from "axios";

export const userAuthStore = defineStore({
   id:'userAuthStore',
   state: () => ({
      user:null,
      token:localStorage.getItem('token') ,
       code_verifier:window.localStorage.getItem('code_verifier') ,
       name:localStorage.getItem('user'),
       avatar:localStorage.getItem('avatar'),
   }),
    share: {
        // An array of fields that the plugin will ignore.
        // Override global config for this store.
        enable: true,
        initialize: true,
    },
   getters: {
     getToken: (state) => {
         return state.token;
     },
       getCode: (state) => {
         return state.code_verifier;
     }
   },
    actions: {
        setUserToken(token) {
            this.token = token
        },
        setUserName(name){
            this.name  = name;
        },
        setUserAvatar(avatar){
            this.avatar  = avatar;
        },
        setCodeVerifier(code) {
            this.code_verifier = code
        },
        async isSessionActive() {
            try {
                const response = await isSessionExpired(this.token)
                console.log("from store")
                console.log(response)
                if (response.data.status == -1) return 0;
                else return 1;


            } catch (e) {
                console.log(e);
            }
        }
    }
});