import {defineStore} from "pinia";
import {fetchGeneratedContentById, schedule_content, schedule_video, save_draft} from "@/utilities/api";

export const useGeneratedContentStore = defineStore({
    id:'generatedContent',
    state: () => ({
        threads:[],
        generated_content_id:null,
        content_source_id:null,
        thread_id:null,
        outputChannels:[],
        posts: [],
        videos:[],
        videos_id:null,
        publishingDate:  new Date(),
        timezone:  Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoadingContent: false,
        loader_text: "Loading ... ",
    }),
    getters: {
    },
    actions: {
        editTweetById(index , newTweet){
            // this.threads.find(tweet => tweet.tweet_id === tweet_id).tweet = newTweet;
            this.threads[index].tweet = newTweet;
        },
        editPost(post_id , newPost){
            this.posts[post_id].content = newPost;

        },
        detectMove(e){
          console.log(e)
        },

        removeAt(tweet_id) {
            if(confirm("Are you sure? this can't be undone, yet ;)")){
                this.threads.find(tweet => tweet.tweet_id === tweet_id).is_deleted = true;

                // this.threads.splice(idx, 1);
                // this.updateTweetIndex()
//TODO update new thread to the backend
            }
        },
        addAt(idx) {
            let tweet = {
                tweet:"",
            }
            this.threads.splice(idx, 0,tweet);
            this.updateTweetIndex()

        },
        updateTweetIndex(){
            this.threads.forEach((tweet,index) => {

                tweet.order_id  = index
            })
        },
        updateThread(newThread){
            this.$state.threads = newThread;
            this.updateTweetIndex()

        },
        //bind order id to the library order

        async init(){
            try {

                const response = await fetchGeneratedContentById(this.generated_content_id)
                if (response.data.status == -1 )   window.location.href = '/login';

                const generated_content = response.data.generated_content;
                this.content_source_id  =  response.data.content_source
                this.threads = ('tweets' in response.data.generated_content) ? generated_content.tweets : []
                this.thread_id =('tweets' in response.data.generated_content) ?  this.threads[0].thread_id : null;
                this.posts = ('linkedin' in response.data.generated_content) ?  generated_content.linkedin: [];
                this.videos = ('videos' in response.data.generated_content) ?  generated_content.videos : [];
                this.outputChannels = [];
                if('tweets' in response.data.generated_content) this.outputChannels.push("twitter")
                if('linkedin' in response.data.generated_content) this.outputChannels.push("linkedin")
                if('videos' in response.data.generated_content) this.outputChannels.push("videos")

            }catch (e) {
                console.log(e);
            }
        },
        async  scheduleContent(publish_now=false){
            try {
                const response = await schedule_content({
                    threads : this.threads,
                    gc_id:this.generated_content_id,
                    cs_id:this.content_source_id,
                    thread_id:this.thread_id,
                    scheduled_time: this.publishingDate,
                    timezone: this.timezone,
                    now:publish_now
                });
                if (response.data.status == -1 )   window.location.href = '/login';
                await this.init();

            } catch (error) {
                console.error(error);
            }
        },
        async  scheduleVideo(publish_now=false){
            try {
                const response = await schedule_video({
                    threads : this.threads,
                    gc_id:this.generated_content_id,
                    cs_id:this.content_source_id,
                    thread_id:this.thread_id,
                    scheduled_time: this.publishingDate,
                    timezone: this.timezone,
                    now:publish_now
                });
                if (response.data.status == -1 )   window.location.href = '/login';
                await this.init();

            } catch (error) {
                console.error(error);
            }
        },
        async  saveDraft(){
            this.isLoadingContent = true;
            this.loader_text = "Draft being saved ..."

            try {
                const response = await save_draft({
                    threads : this.threads,
                    gc_id:this.generated_content_id,
                    cs_id:this.content_source_id,
                    thread_id:this.thread_id,
                });
                if (response.data.status == -1 )   window.location.href = '/login';

                this.isLoadingContent = false;
                this.loader_text = "Loading ..."
                await this.init();



                // return this.redirectToGeneratedContent(response.data.article_id, response.data.generated_content_id)
            } catch (error) {

                    this.loader_text = "Something went wrong ..."

                    setTimeout(function() {
                        this.loader_text = "Loading .. "

                        window.location.href = '/';
                    }, 2000);

                console.error(error);
            }

        }

    }
});