import {defineStore} from "pinia";
// import axios from "axios";
import router from "@/router";
import {fetch_article, repurpose_article} from '/src/utilities/api'
export const useAddArticleVarStore = defineStore({
   id:'addArticleVariables',
   state: () => ({
      currentStep:1,
       loader_text: "It might take a minute, we are repurposing your content...",
      articleSource:0,
       article:{
          title:'',
           body: ''
       },
       articleURL:'',
       articleFetchedCorrectly: false,
       distribution_channels: [
           {
               id:1,
               name: "twitter"
           },
           {
               id:2,
               name: "linkedin"
           }
       ],
       selectedDistChannels: [],
       isLoadingContent:false
   }),
   getters: {
     isManualSelectedAsSource: (state) => {
         return (state.articleSource == 1) ? true : false;
     },
     isURLSelectedAsSource: (state) => {
         return (state.articleSource == 2) ? true : false;
     },
       isSourceSelected: (state) => {
         return (state.articleSource != 0) ? true : false;
       },
       isLastStep: (state) => {
         return (state.currentStep>=3) ? true:  false;
       },
       showManualView: (state) => {
         return state.isSourceSelected && state.isManualSelectedAsSource && (state.currentStep ==2)
       },
       showURLBasedView: (state) => {
         return state.isSourceSelected && state.isURLSelectedAsSource && (state.currentStep ==2)
       },
       showDistributionChannelView: (state) => {
         return state.isSourceSelected && (state.currentStep ==3)
       },
       isFirstStep: (state) => {
         return (state.currentStep == 1) ? true : false;
       },
       isArticleContentFetched : (state) => {
         return state.articleFetchedCorrectly;
       },
       getDistributionChannels: (state )=> {
         return state.distribution_channels;
        }
   },
    actions: {
       goToNextStep () {
           if(!this.isLastStep) this.currentStep++;
       },
        gotPreviousStep () {
            if(!this.isFirstStep) this.currentStep--;
       },
        async fetchArticleFromURL(url, source){

           try{
               const response = await fetch_article(url, source);
               this.article.title = response.data.title;
               this.article.body =  response.data.content;
           }catch (e) {
                console.log(e)
           }

            this.articleFetchedCorrectly = true;
       },
        redirectToGeneratedContent(article_id, generated_content_id){
            // router.push(
            //     { name: 'ShowRepurposedArticle',
            //         params: {
            //             article_id: article_id,
            //             generated_content_id: generated_content_id
            //         }
            //     })

            router.push('/ShowRepurposedArticle/' +article_id + '/' +generated_content_id)
        },
        async  repurposeArticle(){
            try {
                this.isLoadingContent = true;
                const response = await repurpose_article({
                    article : this.article,
                    dist_channel: this.selectedDistChannels
                     });
                if (response.data.status == -1 )   window.location.href = '/login';
                this.isLoadingContent = false;


                return this.redirectToGeneratedContent(response.data.article_id, response.data.generated_content_id)
            } catch (error) {
                console.error(error);
                if(error.message == "Network Error")
                {
                    this.loader_text = "It is taking longer than expected, results will show up soon on your home page..."

                    setTimeout(function() {
                        this.loader_text = "Repurposing in progress.."

                        window.location.href = '/';
                    }, 2000);
                }
            }
       }

    }
});