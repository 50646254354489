<template>
  <div class="ui one column">
    <div class="addNewArticleDiv margin-top-30" >
      <h1 class="">Enter the Video URL</h1>
      <div class="input-with-button">
        <input style="background-color: #15171f; color: rgb(226, 227, 231);" type="text" placeholder="yotube url: https://www.youtube.com/watch?v=8A5FtK9Xc1o"  v-model="addVideoVarStore.video.url">
      </div>
    </div>
  </div>
</template>

<script setup>
import {useAddVideoVarStore} from "@/stores/addVideoVarStore";

const addVideoVarStore = useAddVideoVarStore();


</script>

<style scoped>

</style>