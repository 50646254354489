<template>
<!-- <div class="login-container">-->
<!--&lt;!&ndash;         <a href="http://localhost/api/auth/redirect" class="btn-google">Login with Google</a>&ndash;&gt;-->
<!--&lt;!&ndash;         <a href="http://localhost/api/auth/twitter/redirect" class="btn-google">Login with Twitter</a>&ndash;&gt;-->
<!--        <button @click="loginWithGoogle" class="btn-google">Login with Twitter</button>-->

<!--      </div>-->

  <div class="ui grid login-container">
    <div class="eight wide computer sixteen wide mobile column ">
  <div class="left-half">
    <img src="@/assets/ContentStreamAI.svg" alt="Your Logo" style="max-width: 80%;">
    <h2 style="color: white;font-size: 1.3rem;">Get 10x more value from your content</h2>
    <p style="font-size: 1.3rem;">You focus on producing original content, we focus on repurposing it into tweets, posts, videos, and more</p>
  </div>
  </div>

    <div class="eight wide computer sixteen wide mobile column">
<div class="right-half">
  <div class="login-form">
    <h2 style="font-size: 1.3rem!important;">Login with Twitter</h2>
    <button @click="loginWithGoogle" class="ui twitter button">
      <i class="twitter icon"></i>
      Login with Twitter</button>

  </div>
</div>


  </div>
  </div>
  </template>
  
  <script setup>  
  import {userAuthStore} from "@/stores/userAuthStore";
  import  axios from "axios";
  import {onMounted} from "vue";


  onMounted(() => {

    // getCSRF();

  })

  // axios.defaults.withCredentials = true;

//   const config = {
// 	headers: {
//     "Accept": "application/json",
//     "Access-Control-Allow-Origin": "*",
//     "X-Requested-With": "XMLHttpRequest"

// 	},
// };

  // axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  // axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:8080';
  // axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_URL;
  axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';

// eslint-disable-next-line no-unused-vars
const userAuthStoreInst = userAuthStore();
// const getCSRF = async () => {
//   await axios.get('http://localhost:3000/sanctum/csrf-cookie');
//
// }
// eslint-disable-next-line no-unused-vars
const loginWithGoogle = async () => {
      try {
        const response = await axios.get(process.env.VUE_APP_BE+'/api/auth/twitter/redirect');
        window.localStorage.clear();
        window.localStorage.setItem('code_verifier', response.data.code_verifier);
        console.log(response)
        userAuthStoreInst.setCodeVerifier(response.data.code_verifier);

        window.location.href = response.data.url;
        // window.open(
        //     response.data.url,
        //     '_blank' // <- This is what makes it open in a new window.
        // );
        // localStorage.setItem('token', response.data.token);
        // userAuthStoreInst.setUserToken(response.data.token);
      } catch (error) {
        console.error(error);
      }
    };
  
  </script>
  
  <style >

  body, html {
    height: 100%;
    margin: 0;
  }
  .login-container {
    display: flex;
    height: 100vh;
  }
  .left-half {
    flex: 1;
    background: url('@/assets/loginBG.png') center/cover;
    display: flex!important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 20px;
    height: 100%; /* Ensure left-half div takes full height */

  }
  .right-half {
    flex: 1;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    padding: 20px!important;
    height: 100%; /* Ensure left-half div takes full height */

  }
  .login-form {
    max-width: 300px;
    text-align: center!important;
  }
  .login-form .button {
    margin-top: 20px;
  }
  .left-half img {
    max-width: 200px;
  }
  @media only screen and (max-width: 767px) {
    .left-half {
      height: 66.66vh; /* 1/3 of the screen height on mobile */
    }

    .left-half img {
      max-width: 60%!important; /* Adjust the maximum width of the logo on mobile */
    }
    h2{
      font-size: 2rem!important;
    }
  }
  </style>