<template>
  <div class="ui dimmer" :class="{'active' :TweetThreadStore.isLoadingContent}">
    <div class="ui large text loader">{{TweetThreadStore.loader_text}}</div>
  </div>
<div class=" ui container grid threadWrapper" style="margin: auto;">
  <div class="sixteen wide column">

    <div @click="TweetThreadStore.addAt(0)" class="ui vertical animated button addTweetButton" tabindex="0">
      <div class="hidden content">Add Tweet</div>
      <div class="visible content">
        <i class="plus icon"></i>
      </div>
    </div>

    <draggable
  v-model="TweetThreadStore.threads"
  tag="SingleTweet"
  @start="drag=true"
  @end="onMoveE"
  :component-data="{ tag: 'SingleTweet', name: 'SingleTweet', type: 'transition' }"
  handle=".handle"
  v-bind="dragOptions"
  item-key="tweet_id">
  <template #item="{element, index}">
    <div>
      <SingleTweet :tweet="element" :index="index"></SingleTweet>
    </div>
   </template>
</draggable>

    <button v-on:click="TweetThreadStore.saveDraft()" class="cs-light-btn" style="margin-top:10px">save draft</button>

    <div style="margin: auto;margin-top:20px">
  <VDatePicker v-model="TweetThreadStore.publishingDate" mode="dateTime" :timezone="TweetThreadStore.timezone" style="display: block; margin:auto">
    
  </VDatePicker>
  <button v-on:click="TweetThreadStore.scheduleContent(false)" class="cs-light-btn" style="margin-top:10px">Schedule for later</button>
  <button v-on:click="TweetThreadStore.scheduleContent(true)" class="cs-dark-btn" style="margin-top:10px">Publish NOW</button>

</div>

<!-- <button class="cs-light-btn" style="margin-top:10px">Schedule this thread</button> -->

</div>
</div>

</template>

<script setup>
import SingleTweet from "@/components/VarianceComponents/SingleTweet.vue";
import {computed} from 'vue';
import {useGeneratedContentStore} from "@/stores/generatedContentStore";
import draggable from 'vuedraggable'

const TweetThreadStore =  useGeneratedContentStore();
// const timezone =TweetThreadStore.publishingDate.getTimezoneOffset() ;

// eslint-disable-next-line no-unused-vars
function onMoveE(e){
   TweetThreadStore.updateTweetIndex()
}

// const threads = computed({
//   get: () => TweetThreadStore.threads.filter(function(tweet){
//     return (tweet.is_deleted == false)
//   }),
//   set: (newThread) => TweetThreadStore.updateThread(newThread),
// })

    const dragOptions = computed(() => {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };

     
})

</script>

<style scoped>

.addTweetButton{
  margin: 10px 0px!important;
  background: white!important;
  border: 1px solid black!important;
  color: #2c2c2c!important;
  min-width: 93px;
}

.addTweetButton:hover{
  background: #2c2c2c!important;;
  border: 1px solid black!important;;
  color: white!important;;
}
.vertical-line {
  margin-top: 100px;
  position: relative; /* Make sure the parent element has a positioning context */
  width: 1px; /* Adjust the width as needed */
  height: 400px; /* Adjust the height as needed */
}

.vertical-line::before {
  content: ''; /* Required for pseudo-elements */
  position: absolute; /* Position the pseudo-element relative to the parent */
  top: 0; /* Adjust the top position as needed */
  right: 0; /* Adjust the right position as needed (for a line on the right) */
  left: 0; /* Adjust the right position as needed (for a line on the right) */
  margin: auto;
  width: 1px; /* Adjust the width of the line as needed */
  height: 100%; /* Make the line the same height as the parent */
  background-color: #333; /* Set the color of the line */
}

.threadWrapper{
  width: 30%!important;
  margin-top: 10px;
}

.tweet{
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; 
  border: 1px solid #999999;
 border-radius: 5px;
 padding-bottom: 20px;
 margin-bottom: 20px;
 width: 100%;
}

textarea{
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; */
  /* border: 1px solid #999999; */
  /* border-radius: 5px; */
  border:none;
  margin-top: 20px;
  resize: none;
  outline: none;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 10px;
  display: block;
  width: 100%;
}

.SingleTweet-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

</style>