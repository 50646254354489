import { createApp } from 'vue'
import App from './App.vue'
import {createPinia} from "pinia";
import { PiniaSharedState } from 'pinia-shared-state';
import router from "./router";
import 'semantic-ui-css/semantic.min.css'
import {setupCalendar,  Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';


const app = createApp(App);
const pinia = createPinia();
pinia.use(
    PiniaSharedState({
        // Enables the plugin for all stores. Defaults to true.
        enable: true,
        // If set to true this tab tries to immediately recover the shared state from another tab. Defaults to true.
        initialize: true,
        // Enforce a type. One of native, idb, localstorage or node. Defaults to native.
        type: 'localstorage',
    }),
);
app.use(pinia);
app.use(router);
app.mount('#app');
app.use(setupCalendar, {})
app.component('v-calendar', Calendar)
app.component('VDatePicker', DatePicker)


