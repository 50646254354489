<template>

  <div style="margin-top: 60px;    background-color:rgb(20 23 31);
    color: white;
    border-radius: 8px;
    padding: 8px;
" class="ui mini compact stackable menu" v-if="(!$route.path.startsWith('/login') && (!$route.path.startsWith('/authCallback')))">
<!--    <div class="header item">-->
<!--      ContentStream AI-->
<!--    </div>-->
    <a href="/" class="item" :class="{ 'active': $route.path.startsWith('/ShowRepurposedVideo') | $route.path.startsWith('/ShowRepurposedArticle') | $route.path === '/'}">
      <i class="home icon"></i>Home</a>
    <router-link to="/AddNewVideo" class=" item" :class="{ 'active': ($route.path.startsWith('/AddNewVideo')  )}"> <i class="video camera icon"></i>Repurpose New Video</router-link>
    <router-link to="/AddNewArticle" class=" item" :class="{ 'active': $route.path.startsWith('/AddNewArticle')}"> <i class="text paragraph icon"></i>Repurpose New Text</router-link>

    <div class="ui simple dropdown item" >

      <img class="ui avatar image" style="border: 2px solid #6723ff;
    min-width: 32px;
    margin-right: 12px !important;
    min-height: 32px;"
           v-bind:src="userAuthStoreInst.avatar">

      <span style='font-size: 1.1rem;
    color: #e2e3e7;
    font-family: Poppins, Avenir, Roboto, "PingFang SC", Helvetica, Arial, "Microsoft YaHei", "WenQuanYi Zen Hei", "WenQuanYi Micro Hei", sans-serif;'>
        {{userAuthStoreInst.name}}</span>
      <div class="menu" style="color:#121212!important;background-color: #e2e3e7!important;">
        <router-link to="/accountSettings" class="item" style= "color: #121212;background-color: #e2e3e7;">Account</router-link>
        <a class="item" style="color: #121212;background-color: #e2e3e7!important;">logout</a>
      </div>
      <i class="dropdown icon"></i>

    </div>


  </div>


      <div>
        <router-view></router-view>
      </div>


<!--  <FooterView/>-->
</template>

<script setup>
import {userAuthStore} from "@/stores/userAuthStore";

const userAuthStoreInst = userAuthStore();



</script>

<style>
.ui.menu .item:hover{
  background-color: #121212!important;
  color: rgb(226, 227, 231)!important;
}
.ui.menu .item.active{
  background-color: rgb(43, 47, 59)!important;
  color: rgb(226, 227, 231)!important;
}

.ui.menu .item{
  border-radius: 8px!important;
  color:rgb(226, 227, 231)!important;
}

body{
  font-size: 16px;
  background-color: #0E1015!important;
}
.margin-btm-30{
  margin-bottom: 30px;
   }

.margin-top-30{
  margin-top: 30px!important;
   }

h1{
  font-size: 2.5vw!important;
  font-weight: 800!important;
}
h1, h2{
  font-family: 'Inter', sans-serif!important;
}
h2{
  color: #057bc5;
  font-size: 1.5vw!important;
  font-weight: 700!important;
}
.cs-dark-btn{
  min-width: 100px;
  min-height: 42px;
  border-radius: 5px;
  color: white;
cursor: pointer;
  background-color: rgb(103, 35, 255)!important;
  padding: 16px;
  font-size: 1.2rem;
  font-family: Poppins, Avenir, Roboto, 'PingFang SC', Helvetica, Arial, 'Microsoft YaHei', 'WenQuanYi Zen Hei', 'WenQuanYi Micro Hei', sans-serif;
}

.cs-page-title{
  background: #0e0e0e;
  font-size: 1.6rem!important;
  color: rgb(226, 227, 231);
  width: 47%;
  margin: auto;
  border-radius: 6px;
  padding: 19px 0px;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(226, 227, 231);
}
</style>
