import axios from 'axios';


// eslint-disable-next-line no-unused-vars
import {defineStore} from "pinia";


// import {userAuthStore} from "@/stores/userAuthStore";

// const userAuthStoreInst = userAuthStore();
axios.defaults.headers.common['content-type'] = "application/json";
axios.defaults.headers.common['Accept'] =  "application/json";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_URL;
axios.defaults.headers.common.Authorization = `${localStorage.getItem('token')}`

axios.defaults.withCredentials = true;

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_BE+'/api',
    withCredentials: true,
    timeout: 500000,
});


const aiClient = axios.create({
    baseURL: process.env.VUE_APP_AI,
});

export async function repurpose_article(repurpose_article_object) {
    return apiClient.post('/repurposeArticle', repurpose_article_object);

}

export async function repurpose_video(repurpose_video_object) {
    return apiClient.post('/repurposeVideo', repurpose_video_object);

}

export async function fetchGeneratedContentById(generated_content_id) {
    return apiClient.get('/fetchGeneratedContent?id='+ generated_content_id);

}
export async function isSessionExpired(token) {
    return apiClient.get('/isValid?token='+ token);

}
export async function fetchAllContentForUser() {
    return apiClient.get('/fetchAllContent');

}
export async function getUserConnectedAccounts() {
    return apiClient.get('/getUserConnectedAccounts');

}

export async function schedule_content(content) {
    return apiClient.post('/scheduleContent', content);

}
export async function schedule_video(video) {
    return apiClient.post('/scheduleVideo', video);

}
export async function save_draft(content) {
    return apiClient.post('/saveDraft', content);

}

export async function fetch_article(url, source) {
    return aiClient.get('/fetchArticle?article_url=' + url + '&source='+source);

}

