<template>

  <div class="fullRowWrapper" style="display: flex;  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  align-items: flex-start;">

    <div style="float: left;margin-left: 20px;    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;

">



      <div class="video-wrapper">
        <div >
          <iframe width="250" height="400" :src="'https://www.youtube.com/embed/' +video.videoURL+'?start='+hmsToSecondsOnly(video.start_time)+'&end='+hmsToSecondsOnly(video.end_time)+''">
          </iframe>
        </div>
        <div>


          <div>
            <button style="background-color: rgb(103, 35, 255);color: white;margin-top: 10px" class="ui twitter button">
              <i class="bi bi-download   icon"></i>
              Download
            </button>

          </div>

        </div>
      </div>
      <!--    <div>-->
      <!--      {{video.start_time}}-->
      <!--    </div>-->
      <!--    <div>-->
      <!--      {{video.end_time}}-->
      <!--    </div>-->

      <div class="cs-title" style="display: flex;  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  max-width: 59%;">

        <h5 style="font-weight: lighter!important;
      color:rgb(251, 146, 60);margin-bottom: 0px">
          suggested title</h5>

        <h3 style="margin-top: 10px;
      margin-bottom: 30px;
      color: rgb(226, 227, 231);
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: Poppins, Avenir, Roboto, 'PingFang SC', Helvetica, Arial, 'Microsoft YaHei', 'WenQuanYi Zen Hei', 'WenQuanYi Micro Hei', sans-serif;">
          {{video.title}}
        </h3>

        <!--      <VDatePicker  mode="dateTime" :timezone="generatedContentStore.timezone" style="display: block; margin:auto">-->

      </div>



    </div>




    <div class="scheduleWrapper" style="display: flex;  flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: center;
      align-items: flex-start;display:none!important;">
      <div>

        <VDatePicker v-model="generatedContentStore.publishingDate" mode="dateTime" :timezone="generatedContentStore.timezone" style="display: block; margin:auto">

        </VDatePicker>
      </div>

      <div style="display: flex;  flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;">
        <button v-on:click="generatedContentStore.scheduleVideo(false)" class="cs-light-btn" style="margin-top:10px">Schedule for later</button>
        <button v-on:click="generatedContentStore.scheduleVideo(true)" class="cs-dark-btn" style="margin-top:10px">Publish NOW</button>

      </div>


    </div>

  </div>




<!--<hr>-->
</template>

<script setup>
import {useGeneratedContentStore} from "@/stores/generatedContentStore";

defineProps({
  video: Object,
  index: Number
})

const generatedContentStore = useGeneratedContentStore();

function hmsToSecondsOnly(str) {
  var p = str.split(':'),
      s = 0, m = 1;

  while (p.length > 0) {
    s += m * parseInt(p.pop(), 10);
    m *= 60;
  }

  return s;
}


</script>

<style scoped>

.video-wrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
}
.cs-title{
  margin-left: 16px;

}

</style>