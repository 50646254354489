<template>
  <div class="ui dimmer" :class="{'active' :addArticleVarStore.isLoadingContent}">
    <div class="ui large text loader">{{ addArticleVarStore.loader_text }}</div>
  </div>
  <div class="ui container  margin-top-30">

    <h2 class="cs-page-title" >Repurpose an Article</h2>
    <div class="row">
      <ul class="stepsList">
        <li :class="{active: (addArticleVarStore.currentStep == 1) }"> <div class="stepNumber">1</div> Select Article Source</li>
        <li :class="{active :(addArticleVarStore.currentStep == 2) }"><div class="stepNumber">2</div>Enter/Review Article</li>
        <li :class="{active :(addArticleVarStore.currentStep == 3) }"><div class="stepNumber">3</div>Select Social Channels</li>
      </ul>
    </div>
    <div class="ui one column grid" >
      <div class="one column container grid cs-div margin-top-30"  style="padding-bottom: 30px!important;background-color: #15171f;
    border: none;
    border-radius: 13px;
    box-shadow: 0px 0px 8px 0px #2b2f3b;">
        <select-article-source v-if="addArticleVarStore.currentStep == 1">
        </select-article-source>

        <manual-entry v-if="addArticleVarStore.showManualView">
        </manual-entry>

      <link-source v-if="addArticleVarStore.showURLBasedView">
      </link-source>

        <select-dist-channel v-if="addArticleVarStore.showDistributionChannelView">
        </select-dist-channel>

  <div class="column nextStepRow">

    <button v-if="!addArticleVarStore.isFirstStep" @click="addArticleVarStore.gotPreviousStep" type="button" class="cs-light-btn">
      reselect article source
    </button>


    <button v-if="addArticleVarStore.isLastStep" @click="addArticleVarStore.repurposeArticle" type="button" class="cs-dark-btn">
      Repurpose Article
    </button>

      <button :disabled="!addArticleVarStore.isSourceSelected || addArticleVarStore.isLastStep"
              type="button"
              class="cs-dark-btn"
              v-if="!addArticleVarStore.isLastStep"
              @click = addArticleVarStore.goToNextStep
      >
        Next
      </button>

</div>

    </div>
    </div>

  </div>
</template>

<script setup>
import ManualEntry from "@/components/AddNewArticleComponents/ManualEntry.vue";
import SelectArticleSource from "@/components/AddNewArticleComponents/selectArticleSource.vue";

import {useAddArticleVarStore} from "@/stores/addArticleVarStore";
import LinkSource from "@/components/AddNewArticleComponents/linkSource.vue";
import SelectDistChannel from "@/components/AddNewArticleComponents/selectDistChannel.vue";

const addArticleVarStore = useAddArticleVarStore();

</script>

<style >
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

button.cs-dark-btn:disabled{
  background-color: grey;
}

.stepsWrapper{
}

.stepsList{
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 60px;
}
.stepsList li{
  display: inline-block;
  color: #959595;
}

.stepsList li.active{
  color: #e2e3e7;
  font-weight: bold;
}

.stepsList li .stepNumber{
  border: 1px solid #e2e3e7;
  border-radius: 100%;
  padding: 4px;
  min-width: 2rem;
  display: inline-block;
  margin-right: 8px;
}

.stepsList li.active .stepNumber{
  border: 1px solid #e2e3e7;
  border-radius: 100%;
  padding: 4px;
  color: white;
  min-width: 2rem;
  background: #121212;
  display: inline-block;
}


</style>