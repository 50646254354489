<template>
  <div class="ui grid" style="margin-top: 40px">
<!--    <div class="stretched row">-->
    <div class="one column wide offset" ></div>
      <div class="three wide column cs-side" >
        <div class="ui segment" style="background-color: transparent">
         <ul style="padding-inline-start: 0px;margin-top: 0px">
           <div class="row">
             <li style="display: block; "
                 :class="{
                 'currentlyActive': $route.path.endsWith(generatedContent.id +'/' + generatedContent.generated_content[0].gc_id)}" class="generatedContents"
                 v-for="(generatedContent) in useUserHomeStore.userGeneratedContents" :key="generatedContent.id">

               <router-link class="generatedContentLink"
                  :class="{'current': $route.path.endsWith(generatedContent.id +'/' + generatedContent.generated_content[0].gc_id)}"
                            :to="getRouterLink(generatedContent)">
<!--                 <i class="bi bi-twitter-x"></i>-->
<!--                 <i class="bi bi-youtube"></i>-->
<!--                 <i class="bi bi-youtube"></i>-->
                 {{(generatedContent.type == 'video') ? generatedContent.url : generatedContent.title}}

               </router-link>
             </li>
           </div>

         </ul>
        </div>
      </div>
      <div class="eleven wide column" style="background-color: transparent">
        <div class="ui segment" style="background-color: transparent">

          <router-view></router-view>
        </div>
      </div>
<!--    </div>-->
  </div>
</template>

<script setup>
import {UserHomeStore} from "@/stores/userHomeStore";
// import router from "@/router";
// import { ref, onMounted, watchEffect } from 'vue';


const useUserHomeStore = UserHomeStore();

// const isStoreReady = ref(false);

// // Watch for Pinia store readiness
// watchEffect(() => {
//
//   if (useUserHomeStore) {
//     // Pinia store is ready
//     isStoreReady.value = true;
//   }
// });

useUserHomeStore.getUserContent();
//
// onMounted(() => {
//   //TODO: save the response to the store, then redirect to to correct url
//   // console.log(route.params)
//   if(isStoreReady.value){
//   console.log(useUserHomeStore.userGeneratedContents)
//     const firstItemLink = getRouterLink(useUserHomeStore.userGeneratedContents[0]);
//     console.log("link")
//     console.log(firstItemLink)
//     // Use router.push to navigate to the first item's link
//     router.push(firstItemLink);
//     // router.push('/ShowRepurposedArticle/' +article_id + '/' +generated_content_id)
//   }
//
// })

function getRouterLink(generatedContent) {
  // Check the type of the current list item and return the appropriate router link
  if (generatedContent.generated_content[0].type === 'video') {
    return (generatedContent.generated_content[0]) ? '/ShowRepurposedVideo/'+ generatedContent.id +'/' + generatedContent.generated_content[0].gc_id : '';
  } else if (generatedContent.generated_content[0].type === 'twitter')  {
    return (generatedContent.generated_content[0]) ? '/ShowRepurposedArticle/'+ generatedContent.id +'/' + generatedContent.generated_content[0].gc_id: '';
  }
  else{
    return (generatedContent.generated_content[0]) ? '/ShowRepurposedPost/'+ generatedContent.id +'/' + generatedContent.generated_content[0].gc_id: '';
  }
}
</script>

<style scoped>
.border-bottom{
  border-bottom: 1px #e1e1e1 solid;
}
.current{
  color: white!important;
}
.currentlyActive{
  background-color: rgb(103, 35, 255)!important;
}

.generatedContents:hover{
  background-color: rgb(103, 35, 255)!important;
  border-radius: 5px;
  color: white!important;
  cursor: pointer;

}
.generatedContents:hover a{
  color: white!important;

}
.generatedContents{
  margin-top: 16px;
  color: white!important;
  background-color: #2c2c2c;
  padding: 16px 16px;
  border-radius: 5px;



}

.generatedContentLink:hover{
  border-radius: 5px!important;
  color: white!important;
}
.generatedContentLink{
  color: white;

}
</style>