import {createRouter, createWebHistory} from "vue-router";

// import Home from '../views/UserHome.vue';
import AddNewArticle from '../views/AddNewArticle.vue';
import ShowRepurposedArticle from '../views/ShowRepurposedArticle.vue';
// import UserInfo from '../views/UserInfo.vue';
import AddNewVideo from "@/views/AddNewVideo.vue";
import UserLogin from '../views/UserLogin.vue';
import AuthCallback from '../views/AuthCallback'
import { userAuthStore } from "@/stores/userAuthStore";
import ShowRepurposedVideo from "@/views/ShowRepurposedVideo.vue";
import ShowRepurposedPost from "@/views/ShowRepurposedPost.vue";
import UserHome from "@/views/UserHome.vue";
import AccountSettings from "@/views/AccountSettings.vue";


const routes = [
    {
        path: '/',
        name: 'home',
        component: UserHome,
        children: [
            {
                path: '/ShowRepurposedArticle/:article_id/:generated_content_id',
                name: 'ShowRepurposedArticle',
                component: ShowRepurposedArticle
            },{
                path: '/ShowRepurposedPost/:post_id/:generated_content_id',
                name: 'ShowRepurposedPost',
                component: ShowRepurposedPost
            },
            {
                path: '/ShowRepurposedVideo/:video_id/:generated_content_id',
                name: 'ShowRepurposedVideo',
                component: ShowRepurposedVideo
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: UserLogin
    },
    {
        path: '/authCallback',
        name: 'authCallback',
        component: AuthCallback
    },
    // {
    //     path: '/UserInfo',
    //     name: 'UserInfo',
    //     component: UserInfo
    // },
    {
        path: '/AddNewArticle',
        name: 'AddNewArticle',
        component: AddNewArticle
    },
    {
        path: '/addNewVideo',
        name: 'AddNewVideo',
        component: AddNewVideo
    },

    {
        path: '/authCallback*',
        name: 'AuthCallback',
        component: AuthCallback,


    },
    {
        path: '/accountSettings',
        name: 'accountSettings',
        component: AccountSettings,


    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    mode: 'history'
})
// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from) => {

    const userAuthStoreInst =  userAuthStore();
    // console.log(userAuthStoreInst.getToken)

    if (
      // make sure the user is authenticated
      !userAuthStoreInst.getToken &&
      // ❗️ Avoid an infinite redirect
        (to.name !== 'Login' && to.name !== 'authCallback')
    ) {
      // console.log(to.name)
      // console.log("user not logged in")

        // console.log(userAuthStoreInst.getToken)
        // redirect the user to the login page
       return { name: 'Login' }
    }
    // console.log("from")
    // console.log(from)
    // console.log("to")
    // console.log(to)
    // if( to.name != 'Login' && to.name != 'AuthCallback' && from.name != 'AuthCallback' && from.name != 'Login' ) {
    //     let response = await !userAuthStoreInst.isSessionActive();
    //
    //     if (!response) {
    //         return {
    //             name: 'Login'
    //         }
    //     }
    // }


  })
export default router;