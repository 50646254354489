<template>
  <h1 class="margin-top-30" style="margin-bottom: 16px"> What is the Article Source?</h1>
  <ul>
    <li>
      <input type="radio" id="manual" name="articleSource" value="1" v-model="addArticleVarStore.articleSource"/>
      <label for="manual" class="cs-btn">
        <i class="bi bi-text-paragraph radioIcon"></i>
        <span>Manual</span>
      </label>
      <span class="articleSourceTip">Manually copy-paste the Article</span>
    </li>

    <li>
      <input type="radio" id="substack" name="articleSource"  value="2" v-model="addArticleVarStore.articleSource"/>
      <label for="substack" class="cs-btn">
        <i class="bi bi-link radioIcon"></i>
        <span>Substack URL</span>
      </label>
      <span class="articleSourceTip">Article is published on <span style="font-weight: bold;font-style: italic; text-decoration: underline"> Substack-based </span>blog</span>
    </li>
  </ul>

</template>

<script setup>
import 'bootstrap-icons/font/bootstrap-icons.css';
import {useAddArticleVarStore} from "@/stores/addArticleVarStore";

const addArticleVarStore = useAddArticleVarStore();


</script>

<style scoped>
ul{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-content: center;
  margin-top: 30px;
}
ul li {
  list-style: none; display: inline-block;

}

/* Style the label to look like a button */
input[type="radio"]{
  display: none!important;
}

/* When the checkbox is checked, change the button's appearance */
input[type="radio"]:checked + .cs-btn {
  background-color: rgb(103, 35, 255)!important;
  color: #fff;
  border-color: #121212;
}

.cs-btn{
  display: inline-block;
  padding: 6px 20px 16px 20px;
  border: 1px solid #121212;
  color: #121212;
  border-radius: 5px;
  background-color: rgb(251, 251, 251);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px ;
  cursor: pointer;
  font-size: 1.3rem;
  min-width: 160px;
}

.radioIcon{
  font-size: 3rem;
  display: block;
  margin: auto;
  margin: 16px auto;

}

.radioIcon:hover{
  color: white;
}
.cs-btn:hover{

  border: 1px solid #121212;
  color: white;
  border-radius: 5px;
  background-color: rgb(103, 35, 255)!important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px ;
  cursor: pointer;
}
.choiceTip{
  display: block;
  margin-top:8px;
  color: #616161;
}
</style>