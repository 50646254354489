<template>
  <div class="ui dimmer" :class="{'active' :addVideoVarStore.isLoadingContent}">
    <div class="ui large text loader">{{addVideoVarStore.loader_text}}</div>
  </div>
  <div class="ui container  margin-top-30">
    <h2 class="cs-page-title" >Repurpose a Video</h2>
    <div class="row">
      <ul class="stepsList">
        <li :class="{active: (addVideoVarStore.currentStep == 1) }"> <div class="stepNumber">1</div> Add Video URL </li>
        <li :class="{active :(addVideoVarStore.currentStep ==  2) }"><div class="stepNumber">2</div>Video Output Format</li>
        <li :class="{active :(addVideoVarStore.currentStep ==  3) }"><div class="stepNumber">3</div>Output details</li>
      </ul>
    </div>
    <div class="ui one column grid" >
      <div class="one column container grid cs-div margin-top-30"  style="padding-bottom: 30px!important;background-color: #15171f;
    border: none;
    border-radius: 13px;
    box-shadow: 0px 0px 8px 0px #2b2f3b;" >


      <video-u-r-l v-if="addVideoVarStore.currentStep == 1"></video-u-r-l>
      <select-output-format v-if="addVideoVarStore.currentStep == 2"></select-output-format>
      <output-details v-if="addVideoVarStore.currentStep == 3"></output-details>


        <div class="column nextStepRow">
          <button  v-if="!addVideoVarStore.isFirstStep" @click="addVideoVarStore.gotPreviousStep" type="button" class="cs-light-btn">
           <i class="bi bi-arrow-left" ></i> {{addVideoVarStore.getPrevButtonText}}

          </button>

          <button v-if="addVideoVarStore.isLastStep" @click="addVideoVarStore.repurposeVideo" type="button" class="cs-dark-btn">
            Repurpose Video
          </button>

          <button :disabled="!addVideoVarStore.isURLEntered || addVideoVarStore.isLastStep"
                  type="button"
                  class="cs-dark-btn"
                  v-if="!addVideoVarStore.isLastStep"
                  @click = addVideoVarStore.goToNextStep
          >
            {{addVideoVarStore.getNextButtonText}}
          </button>
        </div>

      </div>
    </div>

  </div>
</template>

<script setup>

import {useAddVideoVarStore} from "@/stores/addVideoVarStore";
import VideoURL from "@/components/VarianceComponents/videoComponents/videoURL.vue";
import SelectOutputFormat from "@/components/VarianceComponents/videoComponents/selectOutputFormat.vue";
import OutputDetails from "@/components/VarianceComponents/videoComponents/outputDetails.vue";

const addVideoVarStore = useAddVideoVarStore();

</script>

<style >
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

button.cs-dark-btn:disabled{
  background-color: rgb(106 106 106) !important;
  padding: 16px;
  font-size: 1.2rem;
  font-family: Poppins, Avenir, Roboto, 'PingFang SC', Helvetica, Arial, 'Microsoft YaHei', 'WenQuanYi Zen Hei', 'WenQuanYi Micro Hei', sans-serif;
}

.stepsWrapper{
}

.stepsList{
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 60px;
}
.stepsList li{
  display: inline-block;
  color: #959595;
}

.stepsList li.active{
  color: #e2e3e7;
  font-weight: bold;
}

.stepsList li .stepNumber{
  border: 1px solid #e2e3e7;
  border-radius: 100%;
  padding: 4px;
  min-width: 2rem;
  display: inline-block;
  margin-right: 8px;
}

.stepsList li.active .stepNumber{
  border: 1px solid #e2e3e7;
  border-radius: 100%;
  padding: 4px;
  color: #e2e3e7!important;
  min-width: 2rem;
  background: #121212;
  display: inline-block;
}


.input-with-button{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}


input[type="text"]{

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border: 1px solid #999999;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 16px 20px;
  max-width: 50%;
}
input[type="text"]:focus-visible,
input[type="text"]:focus,
input[type="text"]:active{
  border: 1px solid #999999;
}

.input-with-button input{
  flex:1;
  padding: 16px 20px;
}
.input-with-button button{
  padding: 15px 0;
  color: #121212;
  background-color: rgb(251, 251, 251);
  min-width: 100px;
  min-height: 42px;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}
.input-with-button button:hover{
  color: #ffffff;
  background-color: #121212;

}

</style>