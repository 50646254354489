<template>
   <h1>Logging in ....</h1>
     </template>
     
     <script setup>
     // eslint-disable-next-line no-unused-vars
     import  axios from "axios";
     import {userAuthStore} from "@/stores/userAuthStore";
     import {onMounted} from "vue";

     const userAuthStoreInst = userAuthStore();

     // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
   // axios.defaults.headers.common['content-type'] = "application/json";
   // axios.defaults.headers.common['Accept'] =  "application/json";

   // TODO solution is by creating the proxy file

   // eslint-disable-next-line no-unused-vars
   import { useRoute } from 'vue-router'

     // eslint-disable-next-line no-unused-vars
     import UserLogin from "@/views/UserLogin.vue";
     // eslint-disable-next-line no-unused-vars
     import UserInfo from "@/views/UserInfo.vue";

     axios.defaults.withCredentials = false;
     axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
     axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_URL;
     axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
    const route = useRoute();
     // eslint-disable-next-line no-unused-vars

     // const getCSRF = async () => {
     //   await axios.get('http://localhost:3000/sanctum/csrf-cookie');
     //
     // }
     // import {computed} from 'vue';

     // eslint-disable-next-line no-unused-vars
     const authTwitterUser = async () => {

       try {
         const response = await axios.get(process.env.VUE_APP_BE+'/api/auth/twitter/callback', {
                                             params: {
                                                 state: ""+route.query.state+"",
                                                 code: ""+route.query.code+"",
                                               code_verifier: ""+userAuthStoreInst.getCode+"",
                                             }
                                         });
         localStorage.setItem('token', response.data.token);
         localStorage.setItem('user', response.data.user.name);
         localStorage.setItem('avatar', response.data.avatar);

         userAuthStoreInst.setUserToken(response.data.token);
         userAuthStoreInst.setUserName(response.data.user.name);
         userAuthStoreInst.setUserAvatar(response.data.avatar);

         window.location.href = process.env.VUE_APP_URL;

       } catch (error) {
         console.error(error);
       }
     };


     // eslint-disable-next-line no-unused-vars
     const authGoogleCallback = async () => {
      try {
          console.log(route.query)
        const response = await axios.get(process.env.VUE_APP_BE+'/api/auth/google/getUserFromToken', {
                                            params: {
                                                token: ""+route.query.code+""
                                            }
                                        });

        localStorage.setItem('token', response.data.token);
        userAuthStoreInst.setUserToken(response.data.token);


        window.location.href = process.env.VUE_APP_URL;


      } catch (error) {
        console.error(error);
      }
    };
    onMounted(() => {
      // console.log(route.query);
      setTimeout(() => {
        authTwitterUser()
      }, 100) // 1 seems to work better for me than 0
      //TODO: save the response to the store, then redirect to to correct url
      // authGoogleCallback()


    })




   
   // const loginWithGoogle = async () => {
   //       try {
   //         const response = await axios.get('http://localhost/api/auth/redirect');
   //         console.log(response);
   //         // localStorage.setItem('token', response.data.token);
   //         // userAuthStoreInst.setUserToken(response.data.token);
   //       } catch (error) {
   //         console.error(error);
   //       }
   //     };
     
     </script>
     
     <style >
  
     </style>