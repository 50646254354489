<template>
  <video-output-details v-if="addVideoVarStore.hasVideoOutput"></video-output-details>
  <text-output-details v-if="addVideoVarStore.hasTextOutput"></text-output-details>
</template>

<script setup>
import {useAddVideoVarStore} from "@/stores/addVideoVarStore";
import VideoOutputDetails
  from "@/components/VarianceComponents/videoComponents/videoOutputDetails/videoOutputDetails.vue";
import TextOutputDetails
  from "@/components/VarianceComponents/videoComponents/videoOutputDetails/textOutputDetails.vue";
const addVideoVarStore = useAddVideoVarStore();
</script>


<style >

input[type="number"] {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border: 1px solid #999999;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 16px 20px;
  max-width: 50%;
  min-width: 45%;
}


ul{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-content: center;
  margin-top: 30px;
}
ul li {
  list-style: none; display: inline-block;

}

/* Style the label to look like a button */
input[type="checkbox"]{
  /*display: none!important;*/
}

/* When the checkbox is checked, change the button's appearance */
/*input:checked ~ .cs-btn {*/
input[type="checkbox"]:checked + .cs-cb {
  background-color: #121212;
  color: #fff;
  border-color: #121212;
}

.cs-cb {
  display: inline-block;
  padding: 6px 20px 16px 20px;
  border: 1px solid #121212;
  color: #121212;
  border-radius: 5px;
  background-color: rgb(251, 251, 251);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px ;
  cursor: pointer;
  font-size: 1.3rem;
  min-width: 160px;
}

.radioIcon{
  font-size: 3rem;
  display: block;
  margin: auto;
  margin: 16px auto;

}

.radioIcon:hover{
  color: white;
}
.cs-cb:hover{

  border: 1px solid #121212;
  color: white;
  border-radius: 5px;
  background-color: #121212;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px ;
  cursor: pointer;
}
.articleSourceTip{
  display: block;
  margin-top:8px;
  color: #616161;
}

</style>