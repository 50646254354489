<template>

  <div class="ui three item menu cs-menu" style="margin:auto!important;padding-right: 24px!important;padding-left: 24px!important;">
    <router-link class="item" style="margin:0px 8px!important;" :class="{ 'active': ($route.path.startsWith('/ShowRepurposedArticle')  )}" v-if="hasTwitter()" :to="getRouteLink('twitter')"
    ><i class="bi bi-twitter-x icon"></i>Twitter</router-link>

    <router-link class="item" style="margin:0px 8px!important;" :class="{ 'LinkedinActive': ($route.path.startsWith('/ShowRepurposedPost')  )}" v-if="hasLinkedin()" :to="getRouteLink('linkedin')"
    > <i class="bi bi-linkedin icon"></i> Linkedin</router-link>

    <router-link class="item" style="margin:0px 8px!important;" :class="{' youtubeActive': ($route.path.startsWith('/ShowRepurposedVideo')  )}" v-if="hasVideo()" :to="getRouteLink('video')"
    > <i class="bi bi-youtube icon"></i> Video</router-link>
  </div>

</template>

<script setup>
import {onMounted} from "vue";
import {useGeneratedContentStore} from "@/stores/generatedContentStore";
// import TwitterThread from "@/components/VarianceComponents/twitterThread.vue";
import {onBeforeRouteUpdate, useRoute} from "vue-router";

const route = useRoute();



const generatedContentStore = useGeneratedContentStore();
generatedContentStore.generated_content_id =  route.params.generated_content_id;


onBeforeRouteUpdate(async  (to, from, next) => {
  // const nextRouteQuery = resolved.query; // Next route's query parameters
  // Access other properties of the next route as needed
  generatedContentStore.generated_content_id =  to.params.generated_content_id;

  await generatedContentStore.init();
  next()

});

function getRouteLink(channel) {
  // Check the type of the current list item and return the appropriate router link
  if(channel == "video"){
    return '/ShowRepurposedVideo/'+ generatedContentStore.content_source_id +'/' + generatedContentStore.generated_content_id ;

  }  else if(channel == "twitter"){
    return '/ShowRepurposedArticle/'+ generatedContentStore.content_source_id +'/' + generatedContentStore.generated_content_id ;

  }else {
    return '/ShowRepurposedPost/'+ generatedContentStore.content_source_id +'/' + generatedContentStore.generated_content_id ;

  }

}

function hasTwitter(){
  return (generatedContentStore.outputChannels.includes("twitter")) ? true : false;
}
function hasVideo(){
  return (generatedContentStore.outputChannels.includes("videos")) ? true : false;
}
function hasLinkedin(){
  return (generatedContentStore.outputChannels.includes("linkedin")) ? true : false;
}

onMounted(() => {
  //TODO: save the response to the store, then redirect to to correct url
  generatedContentStore.init();
})


</script>

<style scoped>
.youtubeActive{
  background-color: #d60303!important;
  color: white!important;
  font-weight: 400!important;
}
.item{
  font-size: 1.3rem !important;
  border-radius: 20px;
  color:white!important;

}

.ui.menu .item:hover{
  background-color:rgb(43, 47, 59)!important;
  color: rgb(226, 227, 231)!important;
}

.icon{
  margin: 0px 9px !important;
  font-size: 21px;
}
.cs-menu{
  padding: 16px!important;
  max-width: 80%!important;
  border-radius: 16px!important;
  background-color: rgb(20, 23, 31)!important;
}
.LinkedinActive{
  background-color: rgb(17 65 193)!important;
  color: white!important;
  font-weight: 400!important;
}

</style>
